<template>
  <label class="switcher" :class="classes">
    <input
      v-bind="$attrs"
      v-model="value"
      class="input"
      type="checkbox"
      :disabled="disabled"
      data-t="st-toggle"
    />
    <span class="switch" :class="props.color" data-t="st-toggle-switch" />
    <span class="label">{{ label }} <slot /></span>
  </label>
</template>
<script setup lang="ts">
interface Props {
  disabled?: boolean
  label?: string
  size?: 's' | 'm'
  modelValue?: boolean
  color?: 'primary' | 'secondary'
}

const props = withDefaults(defineProps<Props>(), {
  disabled: false,
  label: '',
  size: 'm',
  color: 'primary',
})

const emit = defineEmits<{
  (e: 'update:modelValue', value?: boolean): void
}>()

const value = computed({
  get: () => props.modelValue,
  set: (newValue) => {
    emit('update:modelValue', newValue)
  },
})

const sizeValue = computed(() =>
  props.size === 'm'
    ? {
        container: '44px',
        height: '24px',
      }
    : {
        container: '36px',
        height: '20px',
      },
)

const classes = computed(() => [props.size, { disabled: props.disabled }])
</script>

<style scoped>
.switcher .label {
  overflow: hidden;

  margin-left: var(--spacing-150);

  font: var(--desktop-text-md-medium);
  color: var(--text-primary);
  text-overflow: ellipsis;
  white-space: nowrap;
}

.switcher .input {
  position: absolute;

  overflow: hidden;

  width: 0;
  height: 0;
  padding: 0;

  white-space: nowrap;

  opacity: 0;
  border-width: 0;
}

.switcher .switch {
  --switch-container-width: v-bind('sizeValue.container');
  --switch-size: calc(var(--switch-container-width) / 2);

  position: relative;

  display: flex;
  flex-basis: v-bind('sizeValue.container');
  flex-shrink: 0;
  align-items: center;

  height: v-bind('sizeValue.height');

  background-color: var(--button-gray-default);
  border-radius: var(--switch-size);

  transition: background-color 0.2s ease-in-out;
}

.switcher .switch::before {
  content: '';

  position: absolute;
  left: 2px;

  width: calc(var(--switch-size) - var(--spacing-025));
  height: calc(var(--switch-size) - var(--spacing-025));

  background-color: var(--text-primary);
  border-radius: var(--border-radius-full);

  transition: transform 0.2s ease-in-out;
}

.switcher:hover .switch {
  background-color: var(--button-gray-hover);
}

.switcher .input:checked + .switch {
  background-color: var(--button-primary-default);

  &.secondary {
    background-color: var(--button-secondary-default);
  }
}

.switcher .input:checked + .switch::before {
  left: 0;
  transform: translateX(
    calc(var(--switch-container-width) - var(--switch-size))
  );
}

.switcher .input:focus-visible + .switch {
  box-shadow: var(--focus-rings-button-gray);
}

.switcher.disabled .input + .switch {
  opacity: 0.32;
}

.switcher .input:checked:focus-visible + .switch {
  box-shadow: var(--focus-rings-button-primary);
}

.switcher.disabled {
  cursor: not-allowed;
}

.switcher.disabled .label {
  cursor: not-allowed;
}

.switcher {
  cursor: pointer;
  display: flex;
  align-items: center;

  &.s {
    .label {
      margin-left: var(--spacing-125);
      font: var(--mobile-text-content-regular);
    }
  }
}
</style>
